import { render, staticRenderFns } from "./StatClient.vue?vue&type=template&id=f07394ba&scoped=true&"
import script from "./StatClient.vue?vue&type=script&lang=js&"
export * from "./StatClient.vue?vue&type=script&lang=js&"
import style0 from "./StatClient.vue?vue&type=style&index=0&id=f07394ba&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f07394ba",
  null
  
)

export default component.exports