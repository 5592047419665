<!-- StatsLayout.vue -->
<template>
	<div class="stats">
	  <div class="topbar">
		<div class="sv">
		  <fa icon="search" />
		  <input type="text" placeholder="search" v-model="keyword" />
		</div>
		<input type="date" v-model="selectedDate" @change="emitDate" />
		<button @click="applyFilters">Appliquer</button>
	  </div>
	  <slot />
	</div>
  </template>
  
  <script>
  export default {
	data() {
	  return {
		keyword: "",
		selectedDate: null,
	  };
	},
	watch: {
	  keyword(new_val) {
		this.$emit("search", new_val);
	  },
	},
	methods: {
	  emitDate() {
		this.$emit("date-change", this.selectedDate);
	  },
	  applyFilters() {
		this.$emit("apply-filters", { keyword: this.keyword, date: this.selectedDate });
	  },
	},
  };
  </script>
  
  <style scoped>
  .topbar {
	display: flex;
  }
  .topbar > * {
	margin-right: 10px;
  }
  </style>
  