<template>
	<StatsLayout @search="search">
		<!-- Boutons d'exportation et d'impression -->
		<div style="display: flex; justify-content: flex-end; margin-bottom: 10px;" class="nonprintable">
			<!-- Bouton Imprimer -->
			<button @click="imprimer" class="print-button" style="margin-right: 10px">Imprimer</button>
		</div>
		<div class="table">
			<table>
				<thead>
					<tr>
						<th>client</th>
						<th>du</th>
						<th>au</th>
						<th class="right">fois</th>
						<th class="right">montant</th>
						<th class="right">payée</th>
						<th class="right">reste</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="item in stats" :key="item.client">
						<td>{{ item.client }}</td>
						<td>{{ datetime(item.du) }}</td>
						<td>{{ datetime(item.au) }}</td>
						<td>{{ item.fois }}</td>
						<td class="right">{{ money(item.prix) }} FBu</td>
						<td class="right">{{ money(item.payee) }} FBu</td>
						<td class="right">{{ money(item.prix - item.payee) }} FBu</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<th colspan="4"></th>
						<th class="right">{{ money(
							stats.reduce((acc, x) => acc + x.prix, 0)
						)}} FBu
						</th>
						<th class="right">{{ money(
							stats.reduce((acc, x) => acc + x.payee, 0)
						)}} FBu
						</th>
						<th class="right">{{ money(
							stats.reduce((acc, x) => acc + (x.prix - x.payee), 0)
						)}} FBu
						</th>
					</tr>
				</tfoot>
			</table>
		</div>
	</StatsLayout>
</template>

<script>
import StatsLayout from "./stats_layout"

export default {
	components: { StatsLayout },
	data() {
		return {
			stats: this.$store.state.stats_client
		}
	},
	watch: {
		"$store.state.stats_client"(new_val) {
			this.stats = new_val;
		}
	},
	methods: {
		search(keyword) {
			this.stats = this.$store.state.stats_client.filter(item => {
				const searchKeys = ["client", "du", "au"];
				for (const key of searchKeys) {
					if (item[key].toLowerCase().includes(keyword.toLowerCase())) {
						return true;
					}
				}
				return false;
			});
		},

		fetchData() {
			let kiosk_id = this.getActiveKiosk().id;
			let link = this.url + `/commande/stats/?kiosk=${kiosk_id}`;
			axios.get(link, this.headers)
				.then((response) => {
					this.$store.state.stats_client = response.data;
				})
				.catch((error) => {
					this.errorOrRefresh(error, this.fetchData);
				});
		},

		imprimer() {
			let printContent = `
				<table>
					<thead>
						<tr>
							<th>client</th>
							<th>du</th>
							<th>au</th>
							<th class="right">fois</th>
							<th class="right">montant</th>
							<th class="right">payée</th>
							<th class="right">reste</th>
						</tr>
					</thead>
					<tbody>
			`;

			this.stats.forEach(item => {
				printContent += `
					<tr>
						<td>${item.client}</td>
						<td>${this.datetime(item.du)}</td>
						<td>${this.datetime(item.au)}</td>
						<td>${item.fois}</td>
						<td class="right">${this.money(item.prix)} FBu</td>
						<td class="right">${this.money(item.payee)} FBu</td>
						<td class="right">${this.money(item.prix - item.payee)} FBu</td>
					</tr>
				`;
			});

			printContent += `
					</tbody>
					<tfoot>
						<tr>
							<th colspan="4"></th>
							<th class="right">${this.money(this.stats.reduce((acc, x) => acc + x.prix, 0))} FBu</th>
							<th class="right">${this.money(this.stats.reduce((acc, x) => acc + x.payee, 0))} FBu</th>
							<th class="right">${this.money(this.stats.reduce((acc, x) => acc + (x.prix - x.payee), 0))} FBu</th>
						</tr>
					</tfoot>
				</table>
			`;

			const printWindow = window.open('', '', 'width=800,height=600');
			printWindow.document.write('<html><head><title>Statistique des clients</title>');
			printWindow.document.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid black; padding: 5px; text-align: left; } .right { text-align: right; }</style>');
			printWindow.document.write('</head><body>');
			printWindow.document.write(printContent);
			printWindow.document.write('</body></html>');
			printWindow.document.close();
			printWindow.focus();
			printWindow.print();
			printWindow.close();
		}
	},

	mounted() {
		if (this.$store.state.stats_client.length < 1) {
			this.fetchData();
		}
	}
};
</script>

<style scoped>
/* Masquer les éléments non imprimables lors de l'impression */
@media print {
	.nonprintable {
		display: none;
	}
}
</style>
