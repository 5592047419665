<template>
<div class="home">
	<div class="ivyegeranyo">
		<DashCard 
			v-for="icegeranyo, key in Object.entries(ivyegeranyo)"
			:icegeranyo="icegeranyo[1]"
		/>
	</div>
	<div class="legends">
		<div class="ventes">
			<div>
				<select class="end">
					<option>28 jours</option>
					<option>7 jours</option>
					<option>1 jour</option>
					<option>imprimer</option>
				</select>
				<h3>Meilleur ventes</h3>
			</div>
			<div class="table">
				<table>
					<tr>
						<th class="left"><b>No.</b></th>
						<th class="left">Qtt.</th>
						<th class="left">produit</th>
						<th class="left">date</th>
						<th class="left">prix_total</th>
						<th class="left">benefice</th>
					</tr>
					<tr v-for="vente, i in ventes">
						<td><b>{{ i+1 }}</b></td>
						<td>{{ vente.quantite }}</td>
						<td>{{ vente.produit }}</td>
						<td>{{ datetime(vente.date) }}</td>
						<td>{{ money(vente.prix_total) }} FBu</td>
						<td>{{ money(vente.benefice) }} FBu</td>
					</tr>
				</table>
			</div>
		</div>
		<div class="charts">
			<div class="top">
				<select >
					<option>28 jours</option>
					<option>7 jours</option>
					<option>1 jour</option>
					<option>imprimer</option>
				</select>
				<div class="content">
					<div id="pie">
						<div class="percent">{{ money(
							!stock.initial?0:(stock.restant/stock.initial)*100)
						}}%
						</div>
					</div>
					<div class="legend">
						<b>Ecoulement stock</b>
						<div>{{ money(
							!stock.initial?0:(stock.restant/stock.initial)*100)
						}}% stock restant
						</div>
						<div>{{ money(
							!stock.initial?0:((stock.initial-stock.restant)/stock.initial)*100)
						}}% ventes
						</div>
					</div>
				</div>
			</div>
			<div class="bottom">
				<select >
					<option>28 jours</option>
					<option>7 jours</option>
					<option>1 jour</option>
					<option>imprimer</option>
				</select>
				<div class="content">
					<fa class="icon" icon="smile-wink"/>
					<div class="legend">
						<b>Clients</b>
						<div>{{ client.new }}</div>
						<div>{{ 
							(client.new-client.old)/client.old*100
						}}% d'augmentation
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
</div>
</template>
<script>
import DashCard from "../components/dash_card"
export default{
	components:{DashCard,},
	data(){
		return {
			ventes:[],
			ivyegeranyo:{
				interets : {icon:"chart-bar", text:"Intérêts", value:"0"},
				invest : {icon:"money-bill-alt", text:"Invest", value:"0"},
				ventes : {icon:"shopping-cart", text:"Ventes", value:"0"},
				dettes : {icon:"hand-holding-usd", text:"Dettes", value:"0"},
				produits : {icon:"database", text:"Produits", value:"0"}
			},
			stock:{initial:2, restant:1},
			client:{old:0, new:0}
		}
	},
	watch:{
		"$store.state.active_kiosk"(new_val){
			this.fetchVentes()
		}
	},
	methods:{
		fetchVentes(){
			let kiosk_id = this.getActiveKiosk().id
			axios.get(this.url+`/vente/?commande__kiosk=${kiosk_id}&ordering=-benefice`, this.headers)
			.then((response) => {
				this.ventes = response.data.results.slice(0, 20);
				this.fetchVentesTotals()
			}).catch((error) => {
				this.errorOrRefresh(error, this.fetchVentes)
			})
		},
		fetchVentesTotals(){
			let kiosk_id = this.getActiveKiosk().id
			axios.get(this.url+`/vente/totals/?commande__kiosk=${kiosk_id}`, this.headers)
			.then((response) => {
				this.ivyegeranyo.interets.value = this.money(response.data.interets);
				this.ivyegeranyo.ventes.value = this.money(response.data.ventes);
			}).finally(() => {
				this.fetchProduitTotals()
			})
		},
		fetchProduitTotals(){
			let kiosk_id = this.getActiveKiosk().id
			axios.get(this.url+`/produit/totals/?kiosk=${kiosk_id}`, this.headers)
			.then((response) => {
				this.ivyegeranyo.produits.value = this.money(response.data.totals);
			}).finally(() => {
				this.fetchStatsDettes()
			})
		},
		fetchStatsDettes(){
			let kiosk_id = this.getActiveKiosk().id
			axios.get(this.url+`/commande/ovw_dettes/?kiosk=${kiosk_id}`, this.headers)
			.then((response) => {
				this.ivyegeranyo.dettes.value = this.money(response.data.totals);
			}).finally(() => {
				this.fetchStockOverview()
			})
		},
		fetchStockOverview(){
			let kiosk_id = this.getActiveKiosk().id
			axios.get(this.url+`/stock/overview/?produit__kiosk=${kiosk_id}`, this.headers)
			.then((response) => {
				this.stock = response.data;
				let stock_restant = this.money((this.stock.restant/this.stock.initial)*100)
				let stock_vendu = this.money(100 - stock_restant)
				let value;
				if(stock_restant<50){
					value = `conic-gradient(#fff ${stock_vendu}%, #0af ${stock_restant}%)`
				} else {
					value = `conic-gradient(#0af ${stock_restant}%, #fff ${stock_vendu}%)`
				}
				pie.style.backgroundImage = value;
				this.ivyegeranyo.invest.value = this.money(response.data.invests);
			}).finally(() => {
				this.fetchClientOverview()
			})
		},
		fetchClientOverview(){
			let kiosk_id = this.getActiveKiosk().id
			axios.get(this.url+`/commande/ovw_clients/`, this.headers)
			.then((response) => {
				this.client = response.data;
			})
		},
	},
	mounted(){
		this.fetchVentes()
	},
};
</script>
<style scoped>
.home{
	padding: 20px 0 20px 20px;
	height: 100%;
}
.ivyegeranyo{
	display: flex;
	justify-content: flex-start;
	width: 100%;
	overflow: auto;
}
.ivyegeranyo > *{
	margin: 0 10px 10px 0;
}
.legends{
	display: flex;
	max-height: calc(100% - 110px);
}
.legends > div{
	flex-grow: 1;
}
.ventes, .top, .bottom{
	border: 2px solid black;
	position: relative;
}
.ventes{
	margin-right: 20px;
	padding: 0 0 0 20px;
	max-height: calc(100% - 110px);
}
.top{
	margin-bottom: 20px;
}
.charts{
	margin: 0 20px 20px 0;
}
select{
	margin: 5px;
	position: absolute;
	right: 0;
}
td{
	padding: 0;
	height: inherit;
	white-space: nowrap;
}
.table{
	margin-top: 10px;
	padding-bottom: 20px;
	width: 100%;
	height: calc(100% - 35px);
	overflow: auto;
}
.content{
	margin-top:  20px;
	display: flex;
	overflow: hidden;
	align-items: center;
}
#pie{
	width: 60px;
	min-width: 60px;
	height: 60px;
	background-image: conic-gradient(#fff 100%, #0af 0%);
	border-radius: 50%;
	display: flex ;
	justify-content: center;
	align-items: center;
	/*border: 1px solid black;*/
	margin: 10px;
}
#pie div{
	background-color: white;
	width: 40px;
	height: 40px;
	border-radius: 50%;
	display: flex ;
	justify-content: center;
	align-items: center;
	border: 1px solid black;
	font-size: .8em;
	font-weight: 700;
}
.percent{
	color: #0af;
}
.legend div{
	font-size: .7em;
}
.content .icon{
	font-size: 4em;
	margin: 10px;
}
@media only screen and (max-width: 830px) {
	.home{
		overflow-y: auto;
		height: calc(100% - 50px);
	}
	.legends{
		overflow-y: auto;
		flex-wrap: wrap-reverse;
		max-height: none;
		overflow-y: visible;
		align-items:baseline;
	}
	.charts>div{
		min-width: 250px;
		width: 100%;
	}
	.charts, .ivyegeranyo{
		display: flex;
		flex-wrap: wrap;
		height: auto;
		width: inherit;
		align-items: baseline;
		align-content: flex-start;
		justify-content: flex-start;
	}
}
</style>