<template>
	<StatsLayout @search="search">
		<div style="display: flex; justify-content: flex-end; margin-bottom: 10px;">
			<button @click="imprimer" class="print-button" style="margin-right: 10px">Imprimer</button>
		</div>
		<div class="table">
			<table>
				<thead>
					<tr>
						<th>id</th>
						<th>commande</th>
						<th>date</th>
						<th class="right">somme</th>
						<th>user</th>
						<th>options</th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="payment in payments" :key="payment.id">
						<td>{{ payment.id }}</td>
						<td>No. {{ payment.commande }}</td>
						<td>{{ datetime(payment.date) }}</td>
						<td class="right">{{ money(payment.montant) }} FBu</td>
						<td>{{ payment.user }}</td>
						<td>
							<button @click="supprimer(payment)">supprimer</button>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<th colspan="3"></th>
						<th class="right">{{ money(payments.reduce((acc, x) => acc + x.montant, 0)) }} FBu</th>
						<th colspan="2"></th>
					</tr>
				</tfoot>
			</table>
		</div>
	</StatsLayout>
</template>

<script>
import StatsLayout from "./stats_layout"
export default {
	components: { StatsLayout },
	data() {
		return {
			payments: this.$store.state.payments
		}
	},
	watch: {
		"$store.state.payments"(new_val) {
			this.payments = new_val
		}
	},
	methods: {
		supprimer(item) {
			if (confirm("Voulez-vous vraiment supprimer ce payment")) {
				axios.delete(`${this.url}/payment/${item.id}/`, this.headers)
					.then((response) => {
						let index = this.$store.state.payments.indexOf(item)
						this.$store.state.payments.splice(index, 1)
					}).catch((error) => {
						this.errorOrRefresh(error, () => this.supprimer(item))
					});
			}
		},
		search(keyword) {
			this.payments = this.$store.state.payments.filter(x => {
				return JSON.stringify(x).toLowerCase().includes(keyword.toLowerCase())
			})
		},
		fetchData() {
			let link = ""
			let kiosk_id = this.getActiveKiosk().id
			if (!this.next) {
				link = this.url + `/payment/?commande__kiosk=${kiosk_id}`;
			} else {
				link = this.next
			}
			axios.get(link, this.headers)
				.then((response) => {
					this.$store.state.payments.push(...response.data.results)
					if (response.data.next.length > 0) {
						this.next = response.data.next
						this.fetchData()
					} else {
						this.next = null
					}
				}).catch((error) => {
					this.errorOrRefresh(error, this.fetchData)
				});
		},
		imprimer() {
			let printContent = `
				<table>
					<thead>
						<tr>
							<th>id</th>
							<th>commande</th>
							<th>date</th>
							<th class="right">somme</th>
							<th>user</th>
						</tr>
					</thead>
					<tbody>
			`;

			this.payments.forEach(payment => {
				printContent += `
					<tr>
						<td>${payment.id}</td>
						<td>No. ${payment.commande}</td>
						<td>${this.datetime(payment.date)}</td>
						<td class="right">${this.money(payment.montant)} FBu</td>
						<td>${payment.user}</td>
					</tr>
				`;
			});

			printContent += `
					</tbody>
					<tfoot>
						<tr>
							<th colspan="3"></th>
							<th class="right">${this.money(this.payments.reduce((acc, x) => acc + x.montant, 0))} FBu</th>
							<th></th>
						</tr>
					</tfoot>
				</table>
			`;

			const printWindow = window.open('', '', 'width=800,height=600');
			printWindow.document.write('<html><head><title>Historique des Paiements</title>');
			printWindow.document.write('<style>table { width: 100%; border-collapse: collapse; } th, td { border: 1px solid black; padding: 5px; text-align: left; } .right { text-align: right; }</style>');
			printWindow.document.write('</head><body>');
			printWindow.document.write(printContent);
			printWindow.document.write('</body></html>');
			printWindow.document.close();
			printWindow.focus();
			printWindow.print();
			printWindow.close();
		},
	},
	mounted() {
		if (this.$store.state.payments.length < 1) {
			this.fetchData()
		}
	}
};
</script>

<style scoped>
</style>
