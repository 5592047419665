<template>
	<StatsLayout @search="search">
		<div class="option">
			<button @click="printTable">Imprimer</button>
		</div>
		<div class="table nonprintable">
			<table>
				<thead>
					<tr>
						<th>id</th>
						<th class="right">vente</th>
						<th class="right">dettes</th>
						<th class="right">NET</th>
						<th>date</th>
						<th>action</th>
					</tr>
				</thead>
				<tbody>
					<tr 
						v-for="(versement, i) in versements" 
						:key="i" 
						:class="{'active': i === 0 && !versement.user, 'completed': versement.user}">
						<td>{{ i + 1 }}</td>
						<td class="right">{{ money(versement.vente) }}</td>
						<td class="right">{{ money(versement.dettes) }}</td>
						<td class="right">{{ money(versement.vente + versement.dettes) }}</td>
						<td>{{ datetime(versement.date) }}</td>
						<td>
							<button v-if="i === 0 && !versement.user" @click="verser(versement)">
								verser
							</button>
							<span v-else>
								<svg v-if="versement.user" xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="green" class="bi bi-check-circle-fill" viewBox="0 0 16 16">
									<path d="M16 8a8 8 0 1 0-16 0 8 8 0 0 0 16 0zM6.003 11.803l-3.1-3.1 1.2-1.2 1.9 1.9 5-5 1.2 1.2-6.3 6.3z"/>
								</svg>
							</span>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<th></th>
						<th class="right">BIF {{ money(
							versements.reduce((acc, x) => acc + x.vente, 0)
						) }}</th>
						<th class="right">BIF {{ money(
							versements.reduce((acc, x) => acc + x.dettes, 0)
						) }}</th>
						<th class="right">BIF <span style="color:red">{{ money(
							versements.reduce((acc, x) => acc + x.dettes + x.vente, 0)
						) }}</span></th>
						<th colspan="3"></th>
					</tr>
				</tfoot>
			</table>
		</div>
	</StatsLayout>
</template>
<script>
import StatsLayout from "./stats_layout"
export default {
	components: { StatsLayout },
	data() {
		return {
			versements: this.$store.state.versements, 
			next: null
		};
	},
	watch: {
		"$store.state.versements"(new_val) {
			this.versements = new_val;
		}
	},
	methods: {
		search(keyword) {
			this.versements = this.$store.state.versements.filter(x => {
				return JSON.stringify(x).toLowerCase().includes(keyword.toLowerCase());
			});
		},
		fetchData() {
			let link = "";
			let kiosk_id = this.getActiveKiosk().id;
			if (!this.next) {
				link = this.url + `/versement/?kiosk=${kiosk_id}`;
			} else {
				link = this.next;
			}
			axios.get(link, this.headers)
				.then((response) => {
					this.$store.state.versements.push(...response.data.results);
					if (response.data.next) {
						this.next = response.data.next;
						this.fetchData();
					} else {
						this.getTodayVersement();
						this.next = null;
					}
				})
				.catch((error) => {
					console.error(error);
					this.errorOrRefresh(error, this.fetchData);
				});
		},
		getTodayVersement() {
			let kiosk_id = this.getActiveKiosk().id;
			axios.get(this.url + `/versement/today/?kiosk=${kiosk_id}`, this.headers)
				.then((response) => {
					this.$store.state.versements.unshift(response.data);
				})
				.catch((error) => {
					this.errorOrRefresh(error, this.getTodayVersement);
				});
		},
		verser(versement) {
			let username = this.active_user.username;
			if (prompt(`pour accepter ce versement tapez ${username}:`) == username) {
				axios.post(this.url + `/versement/`, versement, this.headers)
				.then((response) => {
					this.$store.state.versements[0] = {
					...response.data,
					user: username
					};
					this.getTodayVersement();
				}).catch((error) => {
					this.errorOrRefresh(error, () => verser(versement));
				});
			}
		},
		printTable() {
    const printContent = `
        <table>
            <thead>
                <tr>
                    <th>No.</th>
                    <th>Date</th>
                    <th>Caissier</th>
                    <th>Client</th>
                    <th>Produit</th>
                    <th class="right">Somme</th>
                    <th class="right">Reste</th>
                    <th class="right">Bénéfice</th>
                </tr>
            </thead>
            <tbody>
                ${this.versements.map((item, index) => `
                    <tr>
                        <td>${index + 1}</td>
                        <td>${this.datetime(item.date)}</td>
                        <td>${item.user || 'N/A'}</td>
                        <td>${item.client || 'N/A'}</td>
                        <td>${item.ventes || 'N/A'}</td>
                        <td class="right">${this.money(item.vente)} FBu</td>
                        <td class="right">${this.money(item.dettes)} FBu</td>
                        <td class="right">${this.money(item.vente - item.dettes)} FBu</td>
                    </tr>
                `).join('')}
            </tbody>
        </table>
    `;

    const newWindow = window.open('', '', 'height=600,width=800');
    newWindow.document.write(`
        <html>
            <head>
                <title>Impression</title>
                <style>
                    table {
                        width: 100%;
                        border-collapse: collapse;
                    }
                    th, td {
                        border: 1px solid black;
                        padding: 5px;
                        text-align: left;
                    }
                    th {
                        background-color: #f2f2f2;
                    }
                    .right {
                        text-align: right;
                    }
                </style>
            </head>
            <body>
                <h2>Historique des Versements</h2>
                ${printContent}
            </body>
        </html>
    `);
    newWindow.document.close();
    newWindow.print();
}

	},
	mounted() {
		if (this.$store.state.versements.length < 1) {
			this.fetchData();
		}
	}
};
</script>
<style scoped>
.option {
	display: flex;
	justify-content: flex-end;
	align-items: center;
}
#dette {
	margin-left: 5px;
}
.table {
	height: calc(100vh - 125px);
}
</style>